import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@Components/button'

import { FormControl } from '@Components/formControl'
import TextField from '@Components/textField'

import { SellerActions, SellerNewActions } from '../../../store'
import { RootState } from '../../../store/Store'
import { useSnackbar } from 'notistack'
import { CNAE } from 'src/resources/merchants'
import CircularProgress from '@mui/material/CircularProgress'
import debounce from 'lodash.debounce'

const { updateSeller, clearUpdateResult } = SellerActions

const pdvlist = ['SITEF', 'SCOPE', 'DTEF']
const poslist = ['DARUMA', 'PISMO', 'CLOUDWALK']
const ecomercelist = ['MAXIPAGO']

const SellerEditPage: React.FC<any> = ({ seller }: any) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { editionSuccess, openFeedback, saveLoading, errorMessage, cnaelist } = useSelector(
    (state: RootState) => state.seller,
  )
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const {
    email,
    name,
    brand_name,
    municipal_registration,
    contact,
    phone,
    area_code,
    address,
    complementary_address,
    neighborhood,
    number,
    state,
    city,
    country,
    zip_code,
    merchant_key,
    key_check_digit,
    terminals,
    category,
  } = seller

  const schema = {
    name: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o nome da empresa.`),
      },
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Número de telefone inválido.`),
      },
    },
    zip_code: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Código postal inválido`),
      },
    },
    address: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o endereço.`),
      },
    },
    number: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o número.`),
      },
    },
    neighborhood: {
      presence: {
        allowEmpty: false,
        message: i18n._(t`Informe o bairro.`),
      },
    },
  }

  useEffect(() => {
    return () => {
      dispatch(clearUpdateResult())
    }
  }, [])

  useEffect(() => {
    if (openFeedback) {
      if (editionSuccess) {
        enqueueSnackbar(i18n._(t`Seus dados foram registrados com sucesso.`), {
          variant: 'success',
        })
      } else {
        enqueueSnackbar(errorMessage?.toString() || i18n._(t`genericApiError`), {
          variant: 'error',
        })
      }
    }
  }, [openFeedback])

  const onErrorHandler = (errors) => {
    let element: HTMLElement | undefined = undefined

    Object.keys(errors).map((key) => {
      if (key === ('external_code' || 'city' || 'state')) key = key + 'element'

      const el: HTMLElement = document.querySelector(`[name=${key}]`) as HTMLElement
      if (!element) {
        element = el
      } else {
        if (el.offsetTop < element.offsetTop) element = el
      }
    })
  }

  const onSubmitHandler = (values) => {
    const terminals: any[] = []

    if (values.webpos) {
      terminals?.push({ type: 'WEBPOS', active: true })
    }

    if (values.pdv) {
      terminals?.push({ type: 'PDV', provider: pdvlist[values.pdv_type], quantity: 1, active: true })
    }

    if (values.pos) {
      terminals?.push({ type: 'POS', provider: poslist[values.pos_type], quantity: values.pos_qt, active: true })
    }

    if (values.ecommerce) {
      terminals?.push({ type: 'ECOMMERCE', provider: ecomercelist[values.ecommerce_type], quantity: 1, active: true })
    }

    const result = {
      ...seller,
      creditor: values.creditor,
      email: values.email,
      business_category: cnaelist && cnaelist?.find((item) => item?.external_code === values?.cnae),
      name: values.name,
      brand_name: values.brand_name,
      municipal_registration: values.municipal_registration,
      contact: values.contact,
      area_code: values.phone.substr(0, 2),
      phone: values.phone.substr(2),
      zip_code: values.zip_code,
      address: values.address,
      complementary_address: values.complementary_address,
      number: values.number,
      neighborhood: values.neighborhood,
      external_code: Number(values?.cnae),
      state: values.state,
      city: values.city,
      custom_fields: {
        crm: values.crm,
        notes: values.notes,
        doctor_name: values.doctor_name,
      },
      terminals,
    }

    dispatch(updateSeller(result?.id, result, user))
  }

  const categoryhealth = seller && seller.category && seller.category === 79 ? true : false

  return (
    <Box>
      <Box my='15px' px='20px'>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField label={i18n._(t`País`)} value='Brazil' disabled multiline />
          </Grid>
          <Grid item xs={6}>
            <TextField label={i18n._(t`Moeda`)} value='BRL - Real (R$)' disabled multiline />
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} md={6}>
          <TextField label={i18n._(t`documentNumber`)} value={seller.document_number} disabled multiline />
        </Grid>
      </Box>
      {
        <FormControl
          initialValue={{
            name,
            brand_name,
            municipal_registration,
            contact,
            email,
            phone: `${area_code}${phone}`,
            address,
            complementary_address,
            neighborhood,
            number,
            statestring: state,
            state: state,
            citystring: city,
            city: city,
            country,
            zip_code,
            merchant_key,
            key_check_digit,
            notes: seller?.custom_fields?.notes,
            crm: seller?.custom_fields?.crm,
            doctor_name: seller?.custom_fields?.doctor_name,
            webpos: terminals?.some((el) => el.type === 'WEBPOS'),
            pdv: terminals?.some((el) => el.type === 'PDV'),
            pdv_type: 0,
            pos: terminals?.some((el) => el.type === 'POS'),
            pos_type: 0,
            pos_qt: terminals?.filter((el) => el.type === 'POS').length || 1,
            ecommerce: terminals?.some((el) => el.type === 'ECOMMERCE'),
            ecommerce_type: 0,
            terminals,
            cnae: seller?.business_category?.external_code,
            category,
            creditor: {
              ...seller?.creditor,
            },
          }}
          validationSchema={schema}
          onSubmit={onSubmitHandler}
          onError={onErrorHandler}
        >
          {(data) => {
            return <SellerEditForm {...data} categoryhealth={categoryhealth} user={user} loading={saveLoading} />
          }}
        </FormControl>
      }
    </Box>
  )
}

const SellerEditForm: React.FC<any> = ({
  loading,
  values,
  handleChange,
  errors,
  categoryhealth,
  user,
  setValues,
}: any) => {
  const { getCreditors } = SellerNewActions
  const { i18n } = useSelector((state: RootState) => state.translation)
  const { creditors } = useSelector((state: RootState) => state.sellerNew)
  const [pdv_type_state, set_pdv_type] = useState(values.pdv_type)
  const [pos_type_state, set_pos_type] = useState(values.pos_type)
  const [ecommerce_type_state, set_ecommerce_type] = useState(values.ecommerce_type)
  const { cnaelist } = useSelector((state: RootState) => state.seller)
  const [creditorsList, setCreditorsList] = useState<any[]>([])

  const dispatch = useDispatch()

  const crediting_methods = {
    ACCOUNT: i18n._(t`platformAccount`),
    BANK: 'Banco',
  }

  const crediting_periods = {
    DAILY: 'Diário',
    WEEKLY: 'Semanal',
    MONTHLY: 'Mensal',
  }

  useEffect(() => {
    values.terminals?.map((el) => {
      switch (el.type) {
        case 'PDV':
          pdvlist?.some((pdv, index) => {
            if (pdv === el.provider) {
              set_pdv_type(index)
              return true
            }
          })
          break
        case 'POS':
          poslist?.some((pos, index) => {
            if (pos === el.provider) {
              set_pos_type(index)
              return true
            }
          })
          break
        case 'ECOMMERCE':
          ecomercelist?.some((ecommerce, index) => {
            if (ecommerce === el.provider) {
              set_ecommerce_type(index)
              return true
            }
          })
          break
      }
    })
  }, [])

  useEffect(() => {
    handleChange({ target: { name: 'pdv_type', value: pdv_type_state } })
  }, [pdv_type_state])

  useEffect(() => {
    handleChange({ target: { name: 'pos_type', value: pos_type_state } })
  }, [pos_type_state])

  useEffect(() => {
    handleChange({ target: { name: 'ecommerce_type', value: ecommerce_type_state } })
  }, [ecommerce_type_state])

  useEffect(() => {
    if (creditors) {
      setCreditorsList([...creditors])
    }
  }, [creditors])

  const changeCNAE = (handleChange: (prop: any) => void) => (_, value) => {
    handleChange({ target: { name: 'cnae', value: value.external_code } })
  }

  const search = (_, value) => {
    if (value.length >= minNumberOfCharacters) {
      fetch(value)
    }
  }

  const fetch = debounce((value) => dispatch(getCreditors(user, { name: value, perPage: 10 })), 500)

  const selectCreditor = (_, value) => {
    fetch.cancel()

    if (value) {
      setValues((currentValue) => ({ ...currentValue, creditor: value }))
    }
  }

  const minNumberOfCharacters = 4

  return (
    <Box px='20px'>
      <Box width={1} mt='20px'>
        <Grid container spacing={3}>
          <br />
          <Grid item xs={12} md={6}>
            <TextField label={i18n._(t`E-mail`)} name='email' value={values.email} multiline onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={i18n._(t`Nome da Empresa`)}
              name='name'
              value={values.name}
              onChange={handleChange}
              helperText={errors.name}
              error={Boolean(errors.name)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={i18n._(t`Nome Fantasia`)}
              name='brand_name'
              value={values.brand_name}
              onChange={handleChange}
              helperText={errors.brand_name}
              error={Boolean(errors.brand_name)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={i18n._(t`Inscrição Municipal`)}
              name='municipal_registration'
              value={values.municipal_registration}
              onChange={handleChange}
              helperText={errors.municipal_registration}
              error={Boolean(errors.municipal_registration)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {!!cnaelist?.length && (
              <Autocomplete
                options={cnaelist}
                clearOnEscape
                autoHighlight
                getOptionLabel={(option: CNAE): string => `${option.description}`}
                value={cnaelist?.find((item) => item.external_code === values?.cnae)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='cnaeelement'
                    label={i18n._(t`Área de negócio`)}
                    helperText={errors.cnae}
                    error={Boolean(errors.cnae)}
                    multiline
                  />
                )}
                onChange={changeCNAE(handleChange)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt='50px'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label={i18n._(t`Nome do contato`)}
              name='contact'
              value={values.contact}
              onChange={handleChange}
              helperText={errors.contact}
              error={Boolean(errors.contact)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={i18n._(t`Telefone`)}
              name='phone'
              value={`${values.phone}`}
              onChange={handleChange}
              helperText={errors.phone}
              error={Boolean(errors.phone)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={i18n._(t`CEP`)}
              name='zip_code'
              value={values.zip_code}
              onChange={handleChange}
              error={Boolean(errors.zip_code)}
              helperText={errors.zip_code}
              fullWidth
            />
          </Grid>
          <Grid item xs={9} sm={10}>
            <TextField
              label={i18n._(t`Endereço`)}
              name='address'
              value={values.address}
              onChange={handleChange}
              error={Boolean(errors.address)}
              helperText={errors.address}
              multiline
              fullWidth
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <TextField
              label={i18n._(t`Número`)}
              name='number'
              value={values.number}
              onChange={handleChange}
              error={Boolean(errors.number)}
              helperText={errors.number}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={i18n._(t`Complemento`)}
              name='complementary_address'
              value={values.complementary_address}
              onChange={handleChange}
              error={Boolean(errors.complementary_address)}
              helperText={errors.complementary_address}
              multiline
              fullWidth
            />
          </Grid>
          <Grid item xs={9} sm={10}>
            <TextField
              label={i18n._(t`Bairro`)}
              name='neighborhood'
              value={values.neighborhood}
              onChange={handleChange}
              error={Boolean(errors.neighborhood)}
              helperText={errors.neighborhood}
              multiline
              fullWidth
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <TextField
              label={i18n._(t`Estado`)}
              name='state'
              helperText={errors.state}
              error={Boolean(errors.state)}
              value={values.state}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={i18n._(t`Cidade`)}
              name='city'
              helperText={errors.city}
              error={Boolean(errors.city)}
              value={values.city}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      {categoryhealth && (
        <Box mt='50px'>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                label={i18n._(t`CNES`)}
                name='cnes'
                value={values.cnes}
                onChange={handleChange}
                error={Boolean(errors.cnes)}
                helperText={errors.cnes}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={i18n._(t`CRM`)}
                name='crm'
                value={values.crm}
                onChange={handleChange}
                error={Boolean(errors.crm)}
                helperText={errors.crm}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={i18n._(t`Nome do médico`)}
                name='doctor_name'
                value={values.doctor_name}
                onChange={handleChange}
                error={Boolean(errors.doctor_name)}
                helperText={errors.doctor_name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={i18n._(t`Observações`)}
                name='notes'
                value={values.notes}
                onChange={handleChange}
                error={Boolean(errors.notes)}
                helperText={errors.notes}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {values.creditor && (
        <Box mt='50px' mb='15px'>
          <Box my='15px'>
            <Grid item xs={12}>
              <Typography variant='h5'>{i18n._(t`Creditor`)}</Typography>
            </Grid>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={9} md={10}>
              <Autocomplete
                options={creditorsList}
                clearOnEscape
                autoHighlight
                getOptionLabel={(option: any) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={values.creditor}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n._(t`Creditor`)}
                    helperText={errors.creditor}
                    error={Boolean(errors.creditor)}
                    placeholder={i18n._(t`Digite o nome do creditor`)}
                    data-testid='creditor-input'
                    value={values.creditor.name}
                    required
                  />
                )}
                onChange={selectCreditor}
                onInputChange={search}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <TextField disabled label={i18n._(t`ID da conta`)} name='creditor-id' value={values.creditor?.id} />
            </Grid>
            <Grid item xs={9} md={10}>
              <TextField
                disabled
                label={i18n._(t`Método de crédito`)}
                name='crediting_method'
                value={i18n._(crediting_methods[values.creditor.crediting_method])}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <TextField
                disabled
                label={i18n._(t`Período de recebimento`)}
                name='crediting_frequency'
                value={i18n._(crediting_periods[values.creditor?.crediting_frequency])}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {values.creditor.bank?.id && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField disabled label={i18n._(t`Banco`)} name='creditor-bank-name' value={values.creditor.bank.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              label={i18n._(t`Agência`)}
              name='creditor-bank-agency'
              value={values.creditor?.bank.agency}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              label={i18n._(t`Conta`)}
              name='creditor-bank-account'
              value={values.creditor?.bank.account}
            />
          </Grid>
        </Grid>
      )}

      <Box mb='50px' mt='30px'>
        <Box width={1}>
          <Box mx='auto'>
            <Button
              disabled={loading}
              startIcon={loading && <CircularProgress color='inherit' size={16} />}
              color='primary'
              variant='contained'
              type='submit'
              fullWidth
            >
              {i18n._(t`Salvar`)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { SellerEditPage }
