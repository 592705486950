import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t, date } from '@lingui/macro'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Pagination } from '@Components/pagination'
import { addDays } from 'date-fns'

import BasicDateRangePicker from '@Components/dateRangePicker'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import { SellerEventActions } from '../../../store'
import { NoResults } from '@Components/search'
import { RootState } from '../../../store/Store'
import Paper from '@mui/material/Paper'
import Calendar from 'react-calendar'
import './style.scss'

import { startOfMonth, endOfMonth, getDate, getMonth, getYear } from 'date-fns'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Statement from './components/Statement'
import { Backdrop, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import format from 'date-fns/format'
import { locale } from '@Components/calendar'
import Tabs from '@Components/tabs'
import { formatToDefaultCurrency } from 'src/utils/number'

const { getTransactionsTotal, getTransactionsList, getTransactionsListDetail } = SellerEventActions

type Prop = {
  sellerId: string
  marketplaceId?: string
}

const SellerEvent: React.FC<any> = ({ sellerId, marketplaceId }: Prop) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const { transactionsTotal, transactions, transactionsDetails, transactionLoading, eventError, transactionsResult } =
    useSelector((state: RootState) => state.sellerEvent)
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const getTextColor = (value) => (Number(value) < 0 ? 'error' : undefined)

  const summarys = [
    { name: i18n._(t`Bloqueado + Pendente`), type: 'pending' },
    { name: i18n._(t`Agendado`), type: 'scheduled' },
    { name: i18n._(t`Total a pagar`), type: 'all' },
    { name: i18n._(t`Liquidado`), type: 'settled' },
    { name: i18n._(t`Pago`), type: 'paid' },
  ]

  const [typeView, setTypeView] = React.useState<number>(0)
  const [startDate, setStartDate] = useState(startOfMonth(new Date()))
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [endDate, setEndDate] = useState(endOfMonth(new Date()))
  const [openListTransaction, setOpenListTransaction] = useState(false)

  useEffect(() => {
    setStartDate(startOfMonth(startDate))
    setEndDate(endOfMonth(startDate))
  }, [typeView])

  const getTransactionsByMerchantId = useCallback(
    async (page = 0) => {
      const params = {
        startPaymentDate: startDate,
        endPaymentDate: endDate,
        groupBy: 'paymentMonths',
        page: page,
        perPage: 50,
        marketplace: marketplaceId,
      }

      startDate && endDate && dispatch(getTransactionsList({ merchantID: sellerId, credentials: user, params }))
    },
    [startDate, endDate],
  )

  useEffect(() => {
    dispatch(getTransactionsTotal(Number(sellerId), startDate, endDate, user))
    getTransactionsByMerchantId()
  }, [startDate, endDate, sellerId])

  const initialDateText = i18n._(
    t`${date(new Date(startDate), {
      month: 'numeric',
      year: 'numeric',
      day: 'numeric',
    })}`,
  )

  const endDateText = i18n._(
    t`${date(new Date(endDate), {
      month: 'numeric',
      year: 'numeric',
      day: 'numeric',
    })}`,
  )

  const changeDateHandler = (dates: { startDate: Date; endDate: Date }) => {
    setStartDate(dates.startDate)
    setEndDate(dates.endDate)
  }

  const closeModal = () => {
    setOpenListTransaction(false)
  }

  const renderNoResults = () => {
    const noRegister = !transactionLoading && transactionsResult?.items && !transactionsResult?.items.length
    return (
      noRegister && (
        <Box width={1} display='flex' justifyContent='center' data-testid='card-msg-notTransaction'>
          <NoResults
            message={
              endDate
                ? i18n._(t`Não Existem transações para o perido entre: ${initialDateText} - ${endDateText}`)
                : i18n._(t`Não Existem transações para a data iniciada em: ${initialDateText}`)
            }
          />
        </Box>
      )
    )
  }

  const renderLoading = () => {
    return (
      transactionLoading && (
        <Box display='flex' justifyContent='center' textAlign='center' mb={2}>
          <Box width={1} display='flex' data-testid='card-msg-loading'>
            <CircularProgress color='success' />
          </Box>
        </Box>
      )
    )
  }

  const renderErrorMessage = () => {
    const showMessage = !transactionLoading && eventError
    return (
      showMessage && (
        <Box width={1} display='flex' data-testid='card-msg-error'>
          <NoResults message={i18n._(t`Erro inesperado. Por favor tente mais tarde.`)} />
        </Box>
      )
    )
  }

  const renderTransactionAmount = (obj) => {
    return (
      <div className='react-calendar__tile__value'>
        <Typography variant='subtitle2' color={getTextColor(obj?.total?.net_value)}>
          {formatToDefaultCurrency(Number(obj?.total?.net_value))}
        </Typography>
      </div>
    )
  }

  const onSelectDay = (day) => {
    const params = {
      startPaymentDate: day,
      endPaymentDate: day,
      groupBy: 'paymentMonths',
      page: 1,
      perPage: 50,
      marketplace: marketplaceId,
    }

    dispatch(getTransactionsListDetail({ merchantID: sellerId, credentials: user, params }))
    setOpenListTransaction(true)
    setSelectedDay(day)
  }

  const renderCalendar = () => (
    <Box mt={2} mb={4}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme?.zIndex?.drawer + 1 }}
        open={!transactions}
        onClick={() => false}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Calendar
        locale={language}
        view='month'
        minDetail='month'
        returnValue='range'
        next2Label={null}
        prev2Label={null}
        onClickDay={(day) => onSelectDay(day)}
        showNeighboringMonth={false}
        nextLabel={<ArrowForwardIosIcon />}
        prevLabel={<ArrowBackIosNewIcon />}
        onActiveStartDateChange={({ action, activeStartDate, value, view }: any) => {
          const newEndDate: any = activeStartDate ? endOfMonth(activeStartDate) : null
          setStartDate(activeStartDate)
          setEndDate(newEndDate)
        }}
        tileContent={({ activeStartDate, date, view }) => {
          const obj = transactions?.[getYear(date)]?.[getMonth(date)]?.[getDate(date)]

          return obj ? renderTransactionAmount(obj) : null
        }}
      />
    </Box>
  )

  const renderStatementPerPeriod = () => (
    <Box>
      <Box p='20px'>
        <Box width={1} display='flex' justifyContent='center' py='20px'>
          <Box data-testid='calendar-events'>
            <BasicDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={changeDateHandler}
              language={language}
              maxDate={addDays(startDate, 30)}
              placeholderText={i18n._(t`selecionePeriodo`)}
            />
          </Box>
        </Box>
        <Box
          display={!!startDate && !!endDate ? 'flex' : 'none'}
          width={1}
          height={1}
          justifyContent='center'
          alignItems='center'
        >
          {renderNoResults()}
          {renderLoading()}
          {renderErrorMessage()}
        </Box>

        <Statement transactions={transactions} />
      </Box>
      {!!transactionsResult?.items?.length && (
        <Box display='flex' justifyContent='center' textAlign='center' mb={2}>
          <Pagination
            pages={transactionsResult?.pages}
            onChange={(page) => {
              getTransactionsByMerchantId(page)
            }}
            disabled={transactionLoading}
          />
        </Box>
      )}
    </Box>
  )

  const content = [() => renderCalendar(), () => renderStatementPerPeriod()]

  return (
    <Box width={1} display='flex' flexDirection='column' data-testid='events-page-container'>
      <Box width={1} display='flex' flexDirection='column'>
        <Box my={3} width={1} display='flex' flexWrap='wrap' justifyContent='center'>
          {summarys.map((el, index) => (
            <SummaryCard
              key={`${el.name}-${index}`}
              title={el.name}
              data={transactionsTotal ? transactionsTotal[el.type] : null}
              type={el.type}
            />
          ))}
        </Box>

        <Tabs
          setTypeView={setTypeView}
          currentValue={typeView}
          values={[
            { value: 0, label: i18n._(t`mes`) },
            { value: 1, label: i18n._(t`selectPeriod`) },
          ]}
        />

        {content[typeView]()}
      </Box>

      <Dialog open={openListTransaction} onClose={closeModal} maxWidth='xl' fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} align='center'>
          {
            <Typography variant='h5' color='primary'>
              {i18n._(t`transactionsOfTheDay`)}{' '}
              {format(selectedDay, 'P', {
                locale: locale[language],
              })}
            </Typography>
          }
          <IconButton
            aria-label='close'
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            color='primary'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Statement transactionLoading={transactionLoading} transactions={transactionsDetails} isPerDay={true} />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export const SummaryCard = ({ title, data, type }: any) => {
  return (
    <Box display='flex' flexDirection='column' data-testid={`card-${type}`} mr={1} mb={2} width={'12rem'}>
      <Paper elevation={3}>
        <Box px={2} py={1}>
          <Typography variant='body2'>{title}</Typography>
          <Box display='flex' flexDirection='column' alignItems='flex-end'>
            <Typography className='positive' variant='caption' data-testid={'text-summary-0'}>
              {formatToDefaultCurrency(data ? Number(data.gross) : 0)}
            </Typography>
            <Typography variant='caption' data-testid={'text-summary-1'} style={{ color: '#FF0000' }}>
              {formatToDefaultCurrency(data ? Number(data.discount) : 0)}
            </Typography>
          </Box>
          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
            {formatToDefaultCurrency(data ? Number(data.net) : 0)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export { SellerEvent }
