import { Dispatch } from 'redux'
import { mountAuthHeaders, interceptor } from '../utils'
import { IError } from '@pismo/sdk-core'
import { ICreditorResult, Merchants } from 'src/resources/merchants'
import { IMarketplaceSearchResult, Marketplace } from 'src/resources/marketplace'

export enum CreditorEvents {
  CREDITOR_RESULT = 'CREDITOR_RESULT',
  ERROR_CREDITOR = 'ERROR_CREDITOR',
  LOADING_CREDITOR = 'LOADING_CREDITOR',
  CLEAR_CREDITOR = 'CLEAR_CREDITOR',
  CLEAR_EDITION_RESULT = 'CLEAR_EDITION_RESULT',
  LIST_CEDENT = 'LIST_CEDENT',
  LIST_BANK = 'LIST_BANK',
  LIST_MARKETPLACES = 'LIST_MARKETPLACES',
  SET_OPEN_CREDITOR_FORM_FEEDBACK = 'SET_OPEN_CREDITOR_FORM_FEEDBACK',
  SET_CREDITOR_ID = 'SET_CREDITOR_ID',
}

export interface ICreditorMarketplacesSearchResult {
  per_page: number
  current_page: number
  items: IMarketplaceSearchResult[]
  pages: number
  total_items: 5
}

export interface CreditorAction {
  type: CreditorEvents
  payload?: any
}

export interface Creditor {
  loadingCreditor: boolean
  creditorResult: ICreditorResult | null
  errorCreditor: boolean
  listCedent: any
  listMarketplaces: any
  listBank: any
  result: any
  openFeedback: boolean
  creditor?: ICreditorResult
  editionSuccess: boolean
  registrationSuccess: boolean
  errorMessage: string
  creditorId: string | undefined
}

export const creditor: Creditor = {
  result: null,
  loadingCreditor: false,
  creditorResult: null,
  listCedent: null,
  listBank: null,
  listMarketplaces: null,
  errorCreditor: false,
  openFeedback: false,
  editionSuccess: false,
  registrationSuccess: false,
  errorMessage: '',
  creditorId: undefined,
}

export const creditorReducer = (state: Creditor = creditor, action: CreditorAction): Creditor => {
  switch (action.type) {
    case CreditorEvents.CREDITOR_RESULT:
      return { ...state, creditorResult: action.payload, loadingCreditor: false, errorCreditor: false }
    case CreditorEvents.LOADING_CREDITOR:
      return { ...state, loadingCreditor: action.payload.loading, result: action.payload.result }
    case CreditorEvents.LIST_MARKETPLACES:
      return { ...state, listMarketplaces: action.payload }
    case CreditorEvents.LIST_BANK:
      return { ...state, listBank: action.payload }
    case CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK:
      return {
        ...state,
        openFeedback: action.payload.open,
        editionSuccess: action.payload.result,
        registrationSuccess: action.payload.result,
        errorMessage: action.payload.errorMessage,
      }
    case CreditorEvents.ERROR_CREDITOR:
      return { ...state, errorCreditor: true, loadingCreditor: false }
    case CreditorEvents.CLEAR_CREDITOR:
      return {
        ...state,
        loadingCreditor: false,
        creditorResult: null,
        errorCreditor: false,
      }
    case CreditorEvents.CLEAR_EDITION_RESULT:
      return { ...state, editionSuccess: false, openFeedback: false }
    case CreditorEvents.SET_CREDITOR_ID:
      return { ...state, creditorId: action.payload }
    default:
      return state
  }
}

export interface ICreditorActions {
  getProfileCreditor: (creditorId: number, credentials: any) => (dispatch: Dispatch) => void
  clear: () => CreditorAction
  setOpenFeedback: (open: boolean) => CreditorAction
  updateCreditor: (creditorId: number, credentials: any, props: any) => (dispatch: Dispatch) => void
  setCreditor: (credentials: any, props: any) => (dispatch: Dispatch) => void
  clearEditionResult: () => CreditorAction
  getCreditorSeller: (creditorId: number, page: number, credentials: any) => (dispatch: Dispatch) => void
}

export const CreditorActions: ICreditorActions = {
  getProfileCreditor: (creditorId: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: CreditorEvents.CREDITOR_RESULT, payload: null })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response: ICreditorResult | IError = await MerchantsClient.getCreditor(creditorId)

    if ((response as IError).error) {
      dispatch({ type: CreditorEvents.ERROR_CREDITOR, payload: (response as IError).error.message })
      return
    }
    dispatch({ type: CreditorEvents.CREDITOR_RESULT, payload: response })
  },

  clearEditionResult: () => ({ type: CreditorEvents.CLEAR_EDITION_RESULT }),

  getCreditorSeller: (creditorId: number, page: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: CreditorEvents.LIST_MARKETPLACES, payload: null })
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const response: any | IError = await MarketplaceClient.fetchMarketplacesByMerchantId(creditorId, 10, page)

    if ((response as IError).error) {
      return
    }

    dispatch({ type: CreditorEvents.LIST_MARKETPLACES, payload: response as ICreditorMarketplacesSearchResult })
  },

  updateCreditor: (creditorId: number, credentials: any, props: any) => async (dispatch: Dispatch) => {
    dispatch({ type: CreditorEvents.CREDITOR_RESULT, payload: props })
    dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: true, result: null } })
    dispatch({
      type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK,
      payload: { result: undefined, open: false, errorMessage: '' },
    })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      await MerchantsClient.updateCreditors(creditorId, props)
      dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: false } })
      dispatch({ type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK, payload: { result: true, open: true } })
    } catch (err: any) {
      dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: false } })
      dispatch({
        type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK,
        payload: { result: false, open: true, errorMessage: err.response.data.message },
      })
    }
  },

  setCreditor: (credentials: any, props: any) => async (dispatch: Dispatch) => {
    dispatch({ type: CreditorEvents.CREDITOR_RESULT, payload: props })
    dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: true } })
    dispatch({
      type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK,
      payload: { result: undefined, open: false, errorMessage: '' },
    })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    try {
      const response = await MerchantsClient.setCreditors(props)
      dispatch({ type: CreditorEvents.SET_CREDITOR_ID, payload: response?.data?.creditor?.id })
      dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: false } })
      dispatch({ type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK, payload: { result: true, open: true } })
    } catch (err: any) {
      dispatch({ type: CreditorEvents.LOADING_CREDITOR, payload: { loading: false } })
      dispatch({
        type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK,
        payload: { result: false, open: true, errorMessage: err?.response?.data?.message },
      })
    }
  },

  clear: () => ({ type: CreditorEvents.CLEAR_CREDITOR }),
  setOpenFeedback: (open: boolean) => ({ type: CreditorEvents.SET_OPEN_CREDITOR_FORM_FEEDBACK, payload: open }),
}
